//js ---------------------------------------------------
import 'babel-polyfill';

//plugins **************
//include in webpack file under MergeIntoSingleFilePlugin

//components **************

//on everypage -------------


//video modal
// if(document.querySelector('.video-link-button') !== null){
//   import('./components/video-modals.js');
// }
//resize the window




import './components/menu.js';
